import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Swiper as SwiperType } from "swiper";

import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import "swiper/css";
import { Box, Grid } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { ImageData } from "../../../utils/image/buildImageData";
import Image from "../../elements/Image/Image";
import { useCallback, useState } from "react";

type CarouselProps = {
  imagesData: ImageData[];
};

const InfoCarousel = ({ imagesData }: CarouselProps) => {
  const [swiper, setSwiper] = useState<SwiperType>();

  const handlePrev = useCallback(() => {
    swiper?.slidePrev();
  }, [swiper]);

  const handleNext = useCallback(() => {
    swiper?.slideNext();
  }, [swiper]);

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        "&:hover": {
          "& .prev-indicator": {
            opacity: 1,
            transform: "none",
          },
          "& .next-indicator": {
            opacity: 1,
            transform: "none",
          },
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        sx={{
          position: "absolute",
          top: "50%",
          width: "100%",
          transform: "translate(0, -50%)",
          zIndex: 2,
          paddingLeft: {
            xs: 2,
            md: 8,
            lg: 12,
          },
          paddingRight: {
            xs: 2,
            md: 8,
            lg: 12,
          },
        }}
      >
        <Grid
          sx={{
            opacity: "0",
            transform: "translateX(-10px)",
            transition: "opacity, transform, 0.3s",
            backgroundColor: (theme) => `${theme.palette.secondary.main}99`,
            px: 0.5,
          }}
          item
          container
          xs="auto"
          alignItems="center"
          className="prev-indicator"
        >
          <ArrowLeft
            sx={{
              fontSize: {
                xs: 40,
                sm: 50,
              },
              color: (theme) => theme.palette.common.white,
              cursor: "pointer",
              "&:focus, &:hover": { transform: "scale(1.1)" },
              "&:active": { transform: "scale(1)" },
              outline: "none",
              opacity: 1,
            }}
            onClick={handlePrev}
          />
        </Grid>

        <Grid
          sx={{
            opacity: "0",
            transform: "translateX(10px)",
            transition: "opacity, transform, 0.3s",
            backgroundColor: (theme) => `${theme.palette.secondary.main}99`,
            px: 0.5,
          }}
          item
          container
          xs="auto"
          alignItems="center"
          className="next-indicator"
        >
          <ArrowRight
            sx={{
              fontSize: {
                xs: 40,
                sm: 50,
              },
              color: (theme) => theme.palette.common.white,
              cursor: "pointer",
              "&:focus, &:hover": { transform: "scale(1.1)" },
              "&:active": { transform: "scale(1)" },
              outline: "none",
              opacity: "1",
            }}
            onClick={handleNext}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          margin: "auto",
          width: {
            xs: "100%",
          },
        }}
      >
        <Swiper
          modules={[EffectFade]}
          effect="fade"
          onSwiper={(swiper) => setSwiper(swiper)}
          spaceBetween={0}
          slidesPerView={1}
          speed={1000}
          loop
          style={{ height: "100%" }}
        >
          {imagesData.map(({ image, alt }, index) => {
            return (
              <SwiperSlide key={index}>
                <Image alt={alt} image={image} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
};

export default InfoCarousel;
