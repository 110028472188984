import { Info, PhotoCameraRounded, LocationOn } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import Button from "../../elements/button/Button";
import { styled } from "@mui/material/styles";
import InfoCarousel from "./InfoCarousel";
import { ImageData } from "../../../utils/image/buildImageData";
import InfoText from "./InfoText";
import Map, { MapProps } from "./Map";
import Text from "../../elements/Text/Text";
import { isNil } from "lodash";
import InViewAnimationGrid from "../../behaviors/InViewAnimation/InViewAnimationGrid";

type InfoValues = "info-carousel" | "info-location" | "info-text";
type InfoButtons = { value: InfoValues; icon: ReactNode };

const infoButtons: InfoButtons[] = [
  {
    value: "info-text",
    icon: <Info sx={{ color: (theme) => theme.palette.common.white }} />,
  },
  {
    value: "info-carousel",
    icon: (
      <PhotoCameraRounded
        sx={{ color: (theme) => theme.palette.common.white }}
      />
    ),
  },
  {
    value: "info-location",
    icon: <LocationOn sx={{ color: (theme) => theme.palette.common.white }} />,
  },
];

type InfoCardContent = Omit<InfoCardProps, "title"> & {
  activeTab: InfoValues;
};

const InfoCardContent = ({
  activeTab,
  imagesData,
  description,
  location,
}: InfoCardContent) => {
  const activeComponent = useMemo(() => {
    switch (activeTab) {
      case "info-location":
        return <Map location={location} />;
      case "info-text":
        return <InfoText text={description} />;

      default:
        return null;
    }
  }, [activeTab, description, location]);

  return (
    <Grid item sx={{ position: "relative" }} xs={12}>
      {activeComponent}
      <InfoCarousel imagesData={imagesData} />
    </Grid>
  );
};

const ButtonStyled = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.5, 0),
  borderRadius: 0,
  "&:not(last-of-type)": {
    borderRight: "1px solid grey",
  },
  "& .MuiSvgIcon-root": {
    pointerEvents: "none",
  },
  backgroundColor: theme.palette.secondary.main,
}));

type InfoCardProps = {
  imagesData: ImageData[];
  description: string;
  location: MapProps["location"];
  title: string;
};

const InfoCard = ({
  imagesData,
  description,
  location,
  title,
}: InfoCardProps) => {
  const [activeTab, setActiveTab] = useState<InfoValues>();

  const handleChange = useCallback(
    (e) => {
      setActiveTab(e.target.id);
    },
    [setActiveTab]
  );

  return (
    <InViewAnimationGrid
      container
      sx={{
        "& > div:first-of-type": {
          filter: "grayscale(100%)",
        },
        "& .Wrapper-Grid-icon-buttons": {
          position: "absolute",
          top: 0,
          opacity: "0",
          transition: "0.3s",
          height: "32px",
          zIndex: 0,
        },
        "& .Wrapper-Grid-title": {
          transition: "0.3s",
          opacity: 1,
          zIndex: 2,
        },
        "&:hover ": {
          "& .Wrapper-Grid-icon-buttons": {
            zIndex: 2,
            opacity: "1",
          },
          "& > div:first-of-type": {
            filter: "none",
          },
          "& .Wrapper-Grid-title": {
            zIndex: 0,
            opacity: 0,
          },
        },
      }}
    >
      <InfoCardContent
        activeTab={activeTab}
        imagesData={imagesData}
        description={description}
        location={location}
      />

      <Grid
        sx={{ position: "relative" }}
        container
        alignItems="center"
        item
        xs={12}
      >
        <Grid className="Wrapper-Grid-icon-buttons" container item>
          {infoButtons.map(({ value, icon }) => (
            <Grid key={value} container item xs={4}>
              <ButtonStyled
                size="small"
                fullWidth
                onClick={handleChange}
                id={value}
              >
                {icon}
              </ButtonStyled>
            </Grid>
          ))}
        </Grid>

        {!isNil(title) && (
          <Grid
            className="Wrapper-Grid-title"
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.main,
              color: (theme) => theme.palette.common.white,
            }}
            item
            xs={12}
          >
            <Text sx={{ paddingLeft: { xs: 1, sm: 2 } }} variant="h5">
              {title}
            </Text>
          </Grid>
        )}
      </Grid>
    </InViewAnimationGrid>
  );
};

export default InfoCard;
