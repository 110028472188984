import { groupBy, isEmpty, isNil } from "lodash";
import { useMemo } from "react";
import { buildImageData } from "../../../utils/image/buildImageData";
import InfoCardWrapper from "../../views/InfoCard/InfoCardWrapper";

type CMSInfoCardProps = {
  data: GatsbyTypes.PrismicProjectsPage["data"]["projects"];
};

const CMSInfoCard = ({ data }: CMSInfoCardProps) => {
  const builtData = useMemo(
    () =>
      groupBy(
        data?.map(
          ({
            image_1,
            image_2,
            image_3,
            image_4,
            image_5,
            description,
            location,
            location_name,
            title,
          }) => ({
            images: [
              buildImageData(image_1),
              buildImageData(image_2),
              buildImageData(image_3),
              buildImageData(image_4),
              buildImageData(image_5),
            ].filter(({ image }) => !isNil(image)),
            description: description.html,
            location: location,
            locationName: location_name,
            title: title,
          })
        ),
        (item) => item.locationName
      ),
    [data]
  );

  if (isEmpty(builtData)) {
    return null;
  }

  return <InfoCardWrapper data={builtData} />;
};

export default CMSInfoCard;
