import { Grid } from "@mui/material";
import { isNil } from "lodash";
import React, { useCallback, useState } from "react";
import { ImageData } from "../../../utils/image/buildImageData";
import InViewAnimationGrid from "../../behaviors/InViewAnimation/InViewAnimationGrid";
import Divider from "../../elements/Divider/Divider";
import Text from "../../elements/Text/Text";
import PageWrap from "../../modules/PageWrap/PageWrap";
import ClickableTextGroup from "../ClickableTextGroup/ClickableTextGroup";
import InfoCard from "./InfoCard";
import { MapProps } from "./Map";

type Project = {
  title: string;
  location: MapProps["location"];
  images: ImageData[];
  locationName: string;
  description: string;
};

type InfoCardWrapperProps = {
  data: Record<string, Project[]>;
};

const InfoCardWrapper = ({ data }: InfoCardWrapperProps) => {
  const headings = Object.keys(data);
  const [activeProject, setActiveProject] = useState(headings[0]);

  const handleProjectChange = useCallback((locationName) => {
    if (!isNil(locationName)) {
      setActiveProject(locationName);
    }
  }, []);

  return (
    <Grid container>
      <PageWrap>
        <Grid item container xs={12} justifyContent="center">
          <InViewAnimationGrid item xs={12}>
            <ClickableTextGroup
              items={headings.map((item) => ({
                id: item,
                text: item,
                onClick: () => handleProjectChange(item),
              }))}
            />
          </InViewAnimationGrid>
        </Grid>
        <InViewAnimationGrid item xs={12}>
          <Divider sx={{ marginTop: 2 }} />
        </InViewAnimationGrid>
        <InViewAnimationGrid item xs={12}>
          <Text
            sx={{
              marginTop: { xs: 2, sm: 3, md: 4 },
              marginBottom: { xs: 5, sm: 7, md: 9 },
            }}
            variant="h2"
            component="p"
            textAlign="center"
            color="secondary"
          >
            {activeProject}
          </Text>
        </InViewAnimationGrid>
        <InViewAnimationGrid item xs={12}>
          {data[activeProject].map(
            ({ images, description, location, title }, index) => (
              <InfoCard
                key={index}
                imagesData={images}
                description={description}
                location={location}
                title={title}
              />
            )
          )}
        </InViewAnimationGrid>
      </PageWrap>
    </Grid>
  );
};

export default InfoCardWrapper;
