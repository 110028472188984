import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import React from "react";
import RichText from "../../elements/RichText/RichText";

type InfoTextProps = {
  text: string;
};

const RichTextStyled = styled(RichText)(({ theme }) => ({
  opacity: 1,
  height: "100%",
  width: "100%",
  color: theme.palette.common.white,
}));

const InfoText = ({ text }: InfoTextProps) => {
  return (
    <Grid
      sx={{
        backgroundColor: (theme) => `${theme.palette.secondary.main}99`,
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 3,
        height: "100%",
        width: "100%",
        overflowY: "auto",
      }}
    >
      <RichTextStyled
        text={text}
        textProps={{
          sx: {
            padding: (theme) => ({
              xs: theme.spacing(1, 2),
              sm: theme.spacing(3, 4),
            }),
            marginBottom: 0,
            color: (theme) => theme.palette.common.white,
          },
        }}
      />
    </Grid>
  );
};

export default InfoText;
